// Run this example by adding <%= javascript_pack_tag 'application' %> to the head of your layout file,
// like app/views/layouts/application.html.erb.

import React from 'react'
import ReactDOM from 'react-dom'
import App from '@walltowall/hillman-components'

const root = document.getElementById('app')

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<App />, root)
})
